export default {
  GET_NOTIFICATIONS: '@@API/GET_NOTIFICATIONS',
  GET_ANNOUNCEMENTS: '@@API/GET_ANNOUNCEMENTS',
  GET_NEW_NOTIFICATION: '@@API/GET_NEW_NOTIFICATION',
  GET_PUBLIC_CAMPAIGN: '@@API/GET_PUBLIC_CAMPAIGN',
  REFRESH_ANNOUNCEMENTS: '@@API/REFRESH_ANNOUNCEMENTS',
  REFRESH_BENEFITS: '@@API/REFRESH_BENEFITS',
  GET_COUPONS: '@@API/GET_COUPONS',
  GET_PROMOTIONS: '@@API/GET_PROMOTIONS',
  GET_SURVEYS: '@@API/GET_SURVEYS',
  GET_BENEFITS: '@@API/GET_BENEFITS',
  MARK_AS_READ: 'MARK_AS_READ',
  GET_AVAILABLE_SURVEY: '@@API/ET_AVAILABLE_SURVEY',
  GET_ALL_NOTIFICATION: '@@API/GET_ALL_NOTIFICATION',
  REFRESH_ALL_NOTIFICATION: 'REFRESH_ALL_NOTIFICATION',
  UPDATE_SURVEY: 'UPDATE_SURVEY',
  UPDATE_SURVEY_IN_REDEEM: 'UPDATE_SURVEY_IN_REDEEM',
  INSERT_NEW_NOTIFICATION: 'INSERT_NEW_NOTIFICATION',
  INSERT_NEW_COUPON: 'INSERT_NEW_COUPON'
};
