// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brand-js": () => import("./../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-brandlist-js": () => import("./../src/pages/brandlist.js" /* webpackChunkName: "component---src-pages-brandlist-js" */),
  "component---src-pages-card-detail-js": () => import("./../src/pages/CardDetail.js" /* webpackChunkName: "component---src-pages-card-detail-js" */),
  "component---src-pages-download-js": () => import("./../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-get-app-page-js": () => import("./../src/pages/GetAppPage.js" /* webpackChunkName: "component---src-pages-get-app-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-line-app-login-js": () => import("./../src/pages/line-app-login.js" /* webpackChunkName: "component---src-pages-line-app-login-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-merchant-js": () => import("./../src/pages/merchant.js" /* webpackChunkName: "component---src-pages-merchant-js" */),
  "component---src-pages-my-account-js": () => import("./../src/pages/MyAccount.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-cards-page-js": () => import("./../src/pages/MyCardsPage.js" /* webpackChunkName: "component---src-pages-my-cards-page-js" */),
  "component---src-pages-noti-detail-page-js": () => import("./../src/pages/NotiDetailPage.js" /* webpackChunkName: "component---src-pages-noti-detail-page-js" */),
  "component---src-pages-privacy-policy-fb-js": () => import("./../src/pages/privacy-policy-fb.js" /* webpackChunkName: "component---src-pages-privacy-policy-fb-js" */),
  "component---src-pages-qr-code-js": () => import("./../src/pages/qr-code.js" /* webpackChunkName: "component---src-pages-qr-code-js" */),
  "component---src-pages-shop-category-js": () => import("./../src/pages/shop-category.js" /* webpackChunkName: "component---src-pages-shop-category-js" */),
  "component---src-pages-survey-js": () => import("./../src/pages/Survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-term-and-condition-app-js": () => import("./../src/pages/term-and-condition-app.js" /* webpackChunkName: "component---src-pages-term-and-condition-app-js" */),
  "component---src-pages-verify-email-js": () => import("./../src/pages/verify-email.js" /* webpackChunkName: "component---src-pages-verify-email-js" */)
}

