import typeToReducer from 'type-to-reducer';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import TYPES from './types';

const initialState = {
  data: [],
  getSurveys: {}
};

const surveyReducer = typeToReducer(
  {
    [TYPES.GET_SURVEYS]: {
      [PENDING]: state => ({
        ...state,
        getSurveys: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => ({
        ...state,
        data: action.payload,
        getSurveys: {
          isFulfilled: true,
          error: null
        }
      }),
      [REJECTED]: (state, action) => ({
        ...state,
        getSurveys: {
          isRejected: true,
          error: action.payload
        }
      })
    }
  },
  initialState
);

export default surveyReducer;
