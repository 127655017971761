import typeToReducer from 'type-to-reducer';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import TYPES from './types';

const initialState = {
    data: [],
    getNewBrands: {}
};

const newbrandReducer = typeToReducer(
    {
      [TYPES.GET_NEWBRAND]: {
        [PENDING]: state => ({
          ...state,
          getNewBrands: {
            isPending: true,
            error: null
          }
        }),
        [FULFILLED]: (state, action) => ({
          ...state,
          data: action.payload,
          getNewBrands: {
            isFulfilled: true,
            error: null
          }
        }),
        [REJECTED]: (state, action) => ({
          ...state,
          getNewBrands: {
            isRejected: true,
            error: action.payload
          }
        })
      }
    },
    initialState
  );
  
  export default newbrandReducer;


