import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from '../modules/auth/reducer';
import rewardReducer from '../modules/reward/reducer';
import surveyReducer from '../modules/survey/reducer';
import brandReducer from '../modules/brand/reducer';
import categoryReducer from '../modules/category/reducer';
import packageReducer from '../modules/package/reducer';
import newbrandReducer from '../modules/newbrand/reducer';
import privilegeReducer from '../modules/privilege/reducer';
import notificationReducer from '../modules/notification/reducer';

export default combineReducers({
  router: routerReducer,
  auth: authReducer,
  reward: rewardReducer,
  survey: surveyReducer,
  brand: brandReducer,
  category: categoryReducer,
  package: packageReducer,
  privilege: privilegeReducer,
  newbrands: newbrandReducer,
  notification: notificationReducer
});
