import typeToReducer from 'type-to-reducer';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import TYPES from './types';

const initialState = {
  data: [],
  getPlans: {}
};

const packageReducer = typeToReducer(
  {
    [TYPES.GET_PLANS]: {
      [PENDING]: state => ({
        ...state,
        getPlans: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => ({
        ...state,
        data: action.payload,
        getPlans: {
          isFulfilled: true,
          error: null
        }
      }),
      [REJECTED]: (state, action) => ({
        ...state,
        getPlans: {
          isRejected: true,
          error: action.payload
        }
      })
    }
  },
  initialState
);

export default packageReducer;
