import typeToReducer from 'type-to-reducer';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import TYPES from './types';

const initialState = {
  data: null,
  getProfileStatus: {}
};

const filterImage = photoURL => {
  if (photoURL.includes('facebook')) {
    const photoPath = photoURL.split('?');
    return photoPath[0] + '?' + photoPath[1];
  }
  return photoURL;
};

const AuthReducer = typeToReducer(
  {
    [TYPES.GET_PROFILE]: {
      [PENDING]: state => ({
        ...state,
        getProfileStatus: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => ({
        ...state,
        data: {
          ...action.payload,
          photoURL: filterImage(action.payload.photoURL)
        },
        getProfileStatus: {
          isFulfilled: true,
          error: null
        }
      }),
      [REJECTED]: (state, action) => ({
        ...state,
        getProfileStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    }
  },
  initialState
);

export default AuthReducer;
