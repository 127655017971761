import typeToReducer from 'type-to-reducer';
import get from 'lodash/get';
import unionBy from 'lodash/unionBy';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import TYPES from './types';

const initialState = {
  data: [],
  query: {},
  getBrandsByQuery: {},
  getBrandById:{},
  getBrands: {},
  activeCategory: '',
  brandLists:[]
};

const addQueryData = (query, path, newCategoryData) => {
  if (get(query, path)) {
    return unionBy(query[path], newCategoryData, 'id');
  } else return newCategoryData;
};

const brandReducer = typeToReducer(
  {
    [TYPES.GET_BRANDS]: {
      [PENDING]: state => ({
        ...state,
        getBrands: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => ({
        ...state,
        data: action.payload,
        getBrands: {
          isFulfilled: true,
          error: null
        }
      }),
      [REJECTED]: (state, action) => ({
        ...state,
        getBrands: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [TYPES.GET_BRANDS_BY_CATEGORY]: (state, action) => ({
      ...state,
      query: {
        ...state.query,
        [action.payload.queryPath]: addQueryData(
          state.query,
          action.payload.queryPath,
          action.payload.data
        )
      }
    }),
    [TYPES.CHANGE_ACTIVE_CATEGORY]: (state, action) => ({
      ...state,
      activeCategory: action.payload
    }),
    [TYPES.GET_BRAND_BY_ID]:{
      [PENDING]: state => ({
        ...state,
        getBrandById: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) =>{ 
        return {
        ...state,
        brandLists: unionBy(state.brandLists, [action.payload], 'id'),
        getBrandById: {
          isFulfilled: true,
          error: null
        }
      }},
      [REJECTED]: (state, action) => ({
        ...state,
        getBrandById: {
          isRejected: true,
          error: action.payload
        }
      })
    }
  },
  initialState
);

export default brandReducer;
