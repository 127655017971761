import typeToReducer from 'type-to-reducer';
import sortBy from 'lodash/sortBy';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import TYPES from './types';

const initialState = {
  data: {},
  getRewards: {},
  createReward: {},
  updateReward: {},
  deleteReward: {}
};

const campaignReducer = typeToReducer(
  {
    [TYPES.CLEAR_REWARD_STATUS]: state => ({
      ...state,
      getRewards: {},
      createReward: {},
      updateReward: {},
      deleteReward: {}
    }),
    [TYPES.GET_REWARDS]: {
      [PENDING]: state => ({
        ...state,
        getRewards: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => {
        if (action.payload.length) {
          return {
            ...state,
            data: {
              ...state.data,
              [action.payload[0].brand]: action.payload
            },
            getRewards: {
              isFulfilled: true,
              error: null
            }
          };
        } else {
          return {
            ...state,
            data: {
              ...state.data
            },
            getRewards: {
              isFulfilled: true,
              error: null
            }
          };
        }
      },
      [REJECTED]: (state, action) => ({
        ...state,
        getRewards: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [TYPES.CREATE_REWARD]: {
      [PENDING]: state => ({
        ...state,
        createReward: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => ({
        ...state,
        data: sortBy([...state.data, action.payload], ['point']),
        createReward: {
          isFulfilled: true,
          error: null
        }
      }),
      [REJECTED]: (state, action) => ({
        ...state,
        createReward: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [TYPES.DELETE_REWARD]: {
      [PENDING]: state => ({
        ...state,
        deleteReward: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => ({
        ...state,
        data: state.data.filter(({ id }) => id !== action.payload.id),
        deleteReward: {
          isFulfilled: true,
          error: null
        }
      }),
      [REJECTED]: (state, action) => ({
        ...state,
        deleteReward: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [TYPES.UPDATE_REWARD]: {
      [PENDING]: state => ({
        ...state,
        updateReward: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => ({
        ...state,
        data: state.data.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, ...action.payload };
          }
          return item;
        }),
        updateReward: {
          isFulfilled: true,
          error: null
        }
      }),
      [REJECTED]: (state, action) => ({
        ...state,
        updateReward: {
          isRejected: true,
          error: action.payload
        }
      })
    }
  },
  initialState
);

export default campaignReducer;
