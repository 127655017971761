import typeToReducer from 'type-to-reducer';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import TYPES from './types';

const initialState = {
  data: {
    reward:[]
  },
  getPrivileges: {}
};

const privilegeReducer = typeToReducer(
  {
    [TYPES.GET_PRIVILEGES]: {
      [PENDING]: state => ({
        ...state,
        getPrivileges: {
          isPending: true,
          error: null
        }
      }),
      [FULFILLED]: (state, action) => ({
        ...state,
        data: action.payload,
        getPrivileges: {
          isFulfilled: true,
          error: null
        }
      }),
      [REJECTED]: (state, action) => ({
        ...state,
        getPrivileges: {
          isRejected: true,
          error: action.payload
        }
      })
    }
  },
  initialState
);

export default privilegeReducer;
