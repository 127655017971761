import typeToReducer from 'type-to-reducer';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import unionBy from 'lodash/unionBy';
import NOTIFICATION_TYPES from './types';

const initialState = {
  data: [],
  coupons: [],
  promotions: [],
  surveys: [],
  announcements: [],
  getBenefitsStatus: {
    error: null
  },
  getAnnouncementsStatus: {
    error: null
  },
  getNotificationsStatus: {
    error: null
  },
  getCouponsStatus: {
    error: null
  },
  getPromotionsStatus: {
    error: null
  },
  getSurveysStatus: {
    error: null
  },
  getNewNotificationStatus: {
    error: null
  },
  getPublicCampaignStatus: {
    error: null
  },
  getAllNotificationStatus: {
    error: null
  },
  shouldGetMoreAllNotification: true,
  shouldGetMoreCoupon: true
};

const isEqualType = type => ({ data = {}, type: campaignType }) =>
  data.type === type || campaignType === type;
const getUniqueNotifications = items => uniqBy(items, 'id');
const getByType = (action, type) =>
  getUniqueNotifications(action.payload.filter(isEqualType(type)));
const orderNotifications = notifications =>
  getUniqueNotifications(
    orderBy(
      notifications,
      [
        ({ createdAt }) => {
          const date = new Date(createdAt);
          return date.getTime();
        }
      ],
      ['desc']
    )
  );
const insertNotificationByType = (list, action, type) => {
  if (get(action, 'payload.type') === type) {
    return orderNotifications([action.payload, ...list]);
  }
  return list;
};

const insertNotificationData = (list, action) => {
  if (
    get(action, 'payload.type') === 'survey' ||
    get(action, 'payload.type') === 'announcement'
  ) {
    return orderNotifications([action.payload, ...list]);
  }
  return list;
};

const markAsRead = (notifications, notificationId) =>
  notifications.map(item => {
    if (item.id === notificationId) {
      return { ...item, markAsRead: true };
    }
    return item;
  });

const updateSurvey = (notifications, surveyData) => {
  const { id, markAsSubmitted, reward } = surveyData;
  return notifications.map(item => {
    if (item.id === id) {
      return {
        ...item,
        markAsSubmitted,
        reward
      };
    }
    return item;
  });
};

const updateSurveyInRedeem = (notifications, surveyData) => {
  const { id } = surveyData;
  return notifications.map(item => {
    if (item.id === id) {
      return {
        ...item,
        markAsUsed: true
      };
    }
    return item;
  });
};

const notificationReducer = typeToReducer(
  {
    [NOTIFICATION_TYPES.REFRESH_ANNOUNCEMENTS]: (state, action) => ({
      ...state,
      announcements: getByType(action, 'announcement')
    }),
    [NOTIFICATION_TYPES.REFRESH_BENEFITS]: (state, action) => {
      return {
        ...state,
        coupons: get(action.payload, 'coupons', []),
        promotions: get(action.payload, 'promotions', []),
        surveys: action.payload.surveys
      };
    },
    [NOTIFICATION_TYPES.MARK_AS_READ]: (state, action) => ({
      ...state,
      coupons: markAsRead(state.coupons, action.payload),
      promotions: markAsRead(state.promotions, action.payload),
      data: markAsRead(state.data, action.payload)
    }),
    [NOTIFICATION_TYPES.GET_ANNOUNCEMENTS]: {
      PENDING: state => {
        return {
          ...state,
          getAnnouncementsStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: (state, action) => ({
        ...state,
        announcements: action.payload,
        getAnnouncementsStatus: {
          isFulfilled: true,
          error: null
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        getAnnouncementsStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.GET_COUPONS]: {
      PENDING: state => {
        return {
          ...state,
          getCouponsStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: (state, action) => ({
        ...state,
        coupons: action.meta
          ? unionBy(state.coupons, action.payload, 'id')
          : action.payload,
        shouldGetMoreCoupon: action.payload.length > 3,
        getCouponsStatus: {
          isFulfilled: true,
          error: null
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        getCouponsStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.GET_PROMOTIONS]: {
      PENDING: state => {
        return {
          ...state,
          getPromotionsStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: (state, action) => ({
        ...state,
        promotions: action.payload,
        getPromotionsStatus: {
          isFulfilled: true,
          error: null
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        getPromotionsStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.GET_SURVEYS]: {
      PENDING: state => {
        return {
          ...state,
          getSurveysStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: (state, action) => {
        return {
          ...state,
          data: unionBy(state.data, action.payload, 'id'),
          getSurveysStatus: {
            isFulfilled: true,
            error: null
          }
        };
      },
      REJECTED: (state, action) => ({
        ...state,
        getSurveysStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.GET_AVAILABLE_SURVEY]: {
      PENDING: state => {
        return {
          ...state,
          getSurveysStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: (state, action) => {
        return {
          ...state,
          surveys: [...state.surveys, ...action.payload],
          getSurveysStatus: {
            isFulfilled: true,
            error: null
          }
        };
      },
      REJECTED: (state, action) => ({
        ...state,
        getSurveysStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.GET_NEW_NOTIFICATION]: {
      PENDING: state => {
        return {
          ...state,
          getNewNotificationStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: (state, action) => ({
        ...state,
        coupons: insertNotificationByType(state.coupons, action, 'coupon'),
        data: insertNotificationData(state.data, action),
        getNewNotificationStatus: {
          isFulfilled: true,
          error: null
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        getNewNotificationStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.GET_PUBLIC_CAMPAIGN]: {
      PENDING: state => {
        return {
          ...state,
          getPublicCampaignStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: state => ({
        ...state,
        getPublicCampaignStatus: {
          isFulfilled: true,
          error: null
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        getPublicCampaignStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.GET_BENEFITS]: {
      PENDING: state => {
        return {
          ...state,
          getBenefitsStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: (state, action) => ({
        ...state,
        coupons: get(action, ['payload', 'coupons'], []),
        promotions: get(action, ['payload', 'promotions'], []),
        getBenefitsStatus: {
          isFulfilled: true,
          error: null
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        getBenefitsStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.GET_ALL_NOTIFICATION]: {
      PENDING: state => {
        return {
          ...state,
          getAllNotificationStatus: {
            isPending: true,
            error: null
          }
        };
      },
      FULFILLED: (state, action) => ({
        ...state,
        data: action.meta
          ? unionBy(state.data, action.payload, 'id')
          : action.payload,
        shouldGetMoreAllNotification: action.payload.length > 5,
        getAllNotificationStatus: {
          isFulfilled: true,
          error: null
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        getAllNotificationStatus: {
          isRejected: true,
          error: action.payload
        }
      })
    },
    [NOTIFICATION_TYPES.REFRESH_ALL_NOTIFICATION]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [NOTIFICATION_TYPES.UPDATE_SURVEY]: (state, action) => {
      return { ...state, data: updateSurvey(state.data, action.payload) };
    },
    [NOTIFICATION_TYPES.UPDATE_SURVEY_IN_REDEEM]: (state, action) => {
      return {
        ...state,
        data: updateSurveyInRedeem(state.data, action.payload)
      };
    },
    [NOTIFICATION_TYPES.INSERT_NEW_COUPON]: (state, action) => ({
      ...state,
      coupons: insertNotificationByType(state.coupons, action, 'coupon')
    }),
    [NOTIFICATION_TYPES.INSERT_NEW_NOTIFICATION]: (state, action) => ({
      ...state,
      data: insertNotificationData(state.data, action)
    })
  },
  initialState
);

export default notificationReducer;
